#cookie {
    line-height: 1;
    padding: 15px;
    position: fixed;
    left: 15px;
    right: 15px;
    bottom: 50px;
    background: rgba(255,255,255,0.8);
    z-index: 200;
}
@media (min-width: 768px) {
    #cookie {
        left: 350px;
        bottom: 40px;
        right: 55px;
        text-align: right;
        background: transparent;
    }
}