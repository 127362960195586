.service-info-gallery {
    margin-bottom: 30px;
}
@media (min-width: 768px) {
    .service-info-gallery {
        margin-bottom: 80px;
    }
}
.service-full-width-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 20%;
}
.service-info {
    position: relative;
    padding-top: 15px;
    margin-bottom: 30px;
}
.service-info .text-ul {
    position: absolute;
    top: -60px;
    text-shadow: 0px 0px 5px rgba(255,255,255,1);
    margin-left: 10px;
}
.service-info-gallery-img,
.service-info-gallery-img-big {
    padding-bottom: 75%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 10px;
}
.service-info-gallery-img-big {
    padding-bottom: 30%;
    margin-bottom: 30px;
}