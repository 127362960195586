#social {
    position: fixed;
    left: 110px;
    bottom: 5px;
    z-index: 100;
    font-size: 20px;
    line-height: 30px;
}
#social a:hover {
    color: #fff265;
}
@media (min-width: 768px) {
    #social {
        left: auto;
        font-size: 30px;
        right: 10px;
        bottom: 2px;
    }
}
@media (min-width: 1200px) and (min-height: 750px) {
    #social {
        right: 80px;
        bottom: 20px;
    }
}