#brand-name {
    position: fixed;
    left: 15px;
    bottom: 10px;
    z-index: 11;
}
#brand-name h1 {
    font-size: 15px;
    margin: 0;
}
@media (min-width: 768px) {
    #brand-name h1 {
        font-size: 30px;
    }
}
@media (min-width: 1200px) and (min-height: 750px) {
    #brand-name {
        left: 20px;
        bottom: 40px;
    }
    #brand-name h1 {
        font-size: 50px;
    }
}