.border-top,
.border-left,
.border-right,
.border-bottom {
    position: fixed;
    background: #fff;
    pointer-events: none;
    z-index: 10;
}
.border-top,
.border-bottom {
    left: 0;
    right: 0;
    height: 40px;
}
.border-top {
    top: 0;
    height: 45px;
}
.border-bottom {
    bottom: 0;
}
.border-left,
.border-right {
    width: 10px;
    top: 0;
    bottom: 0;
}
.border-left {
    left: 0;
}
.border-right {
    right: 0;
}
@media (min-width: 768px) {
    .border-top {
        height: 110px;
    }
}
@media (min-width: 1200px) and (min-height: 750px) {
    .border-top {
        height: 140px;
    }
    .border-bottom {
        height: 80px;
    }
    .border-left,
    .border-right {
        width: 80px;
    }
}