.enrol-slide {
    background: #fff;
}
#enrol-form form {
    opacity: 1;
    transition: opacity 200ms ease;
}
#enrol-form form input,
#enrol-form form select {
    tab-index: -9999;
}
.enrol-slide.active #enrol-form form input,
.enrol-slide.active #enrol-form form select {
    tab-index: 1;
}
#enrol-form form.loading {
    opacity: 0.3;
    pointer-events: none;
}
#enrol-form.active {
    display: block;
}
.form-input {
    position: relative;
    display: table;
    width: 100%;
}
.form-input label {
    display: table-cell;
    width: 1px;
    word-wrap: normal;
    word-break: keep-all;
    padding-right: 20px;
}
.form-input input {
    display: table-cell;
    width: 100%;
    background: #9fdbed;
    border: none;
    padding: 5px 10px;
    margin-bottom: 15px;
}
#submit-enrol-form {
    display: none;
}
#submit-enrol-form.active {
    display: inline-block;
}
#enrol-form input[type="checkbox"] {
    position: relative;
    width: 15px;
    height: 15px;

}
.parsley-errors-list {
    list-style: none;
    padding: 5px 15px;
    background: #fff265;
    position: absolute;
    top: 20px;
    right: 0;
    pointer-events: none;
    z-index: 2;
    max-width: 90vw;
}
.parsley-form-error-left .parsley-errors-list {
    right: auto;
    left: 0;
}
.parsley-errors-list:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 10px 15px;
    border-color: transparent transparent #fff265 transparent;
}
.parsley-success ~ .parsley-errors-list {
    display: none;
}
select {
    margin-left: 7px;
}


.course-dates .radio {
    margin: 0;
    width: 100%;
}
.course-dates label {
    cursor: pointer;
}
.course-dates .radio .label:last-child {
    margin-left: 20px;
}
.course-dates .radio span {
    margin-left: 10px;
}
.course-dates .radio .alert {
    margin-left: 45px;
    margin-bottom: 0px;
    display: block;
    margin-top: 0px;
}
.course-dates input:checked ~ label {
    background: #9fdbed;
}

.dsgvo-section label:last-child {
    display: inline;
    margin-left: 15px;
}
@media (min-width: 767px) {
    .dsgvo-section {
        text-align: right;
    }
}
