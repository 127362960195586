.btn,
button {
    font-family: 'LabGrotesque-Bold', sans-serif;
    font-size: 20px;
    line-height: 1;
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0;
    cursor: pointer;
}
button {
    border: none;
    background: none;
    background-color: transparent;
}
.btn:before,
button:before {
    content: '';
    position: absolute;
    left: -0.2em;
    right: -0.2em;
    bottom: -0.1em;
    height: 0.5em;
    background: #fff265;
    z-index: -1;
}
.btn:hover,
.btn:focus,
.btn:active,
button:hover,
button:focus,
button:active {
    text-decoration: none;
}
@media (min-width: 768px) {
    .btn,
    button {
        font-size: 30px;
    }
}
