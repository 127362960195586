#sujet {
    transform-origin: center top;
    box-shadow: 0 50px 100px rgba(0,0,0,0.25);
    overflow: hidden;
}


#sujet.active {
    transform: translateY(-120%);
    -webkit-animation-name: throwout;
    animation-name: throwout;
    -webkit-animation-duration: 800ms;
    animation-duration: 800ms;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}
@keyframes throwout {
    0%   {  transform: scale(1.00) translateY(   0%) translateZ(0);  }
    40%  {  transform: scale(0.85) translateY(   0%) translateZ(0);  }
    100% {  transform: scale(0.85) translateY(-120%) translateZ(0);  }
}


.sujet-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.sujet-bg img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    max-width: 300%;
    width: auto;
    height: auto;
}
@media (min-width: 768px) {
    #sujet .border-top {
        height: 40px;
    }
}
@media (min-width: 1200px) {
    #sujet .border-top {
        height: 80px;
    }
}


.ad-balloons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 100px 100px;
    overflow-y: scroll;
}
.ad-balloons .balloon {
    position: absolute;
    text-align: center;
    max-width: 300px;
    opacity: 0;
}
#sujet.visible .balloon {
    opacity: 1;
}
.ad-balloons .balloon h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    margin: 0;
}
@media (max-width: 767px) {
    .ad-balloons .balloon h2 {
        font-size: 12px;
    }
    .ad-balloons .balloon:before {
        bottom: -13px;
    }
    .ad-balloons .balloon.left:before {
        transform: rotate(90deg) scaleX(-1) scale(1);
        right: -35px;
    }
    .ad-balloons .balloon.right:before {
        transform: rotate(-90deg) scale(1);
        left: -35px;
    }
}
.ad-balloons .balloon:nth-child(1) { top:  5%; left: 40%; width: 35%; height: 12%; transition: opacity 200ms ease-in; transition-delay: 0ms;}
.ad-balloons .balloon:nth-child(2) { top: 21%; left: 58%; width: 35%; height: 12%; transition: opacity 200ms ease-in; transition-delay: 50ms;}
.ad-balloons .balloon:nth-child(3) { top: 37%; left: 47%; width: 35%; height: 12%; transition: opacity 200ms ease-in; transition-delay: 100ms;}
.ad-balloons .balloon:nth-child(4) { top: 53%; left: 55%; width: 35%; height: 12%; transition: opacity 200ms ease-in; transition-delay: 150ms;}
.ad-balloons .balloon:nth-child(5) { top: 70%; left: 9%; width: 35%; height: 12%; transition: opacity 200ms ease-in; transition-delay: 200ms;}
@media (min-width: 768px) {
    .ad-balloons .balloon {
        padding: 10px;
    }
}
@media (min-width: 1200px) {
    .ad-balloons .balloon h2 {
        font-size: 24px;
    }
    .ad-balloons .balloon:nth-child(1) { top:  5%; left: 50%; width: 15%; height: 15%; }
    .ad-balloons .balloon:nth-child(2) { top: 10%; left: 70%; width: 15%; height: 15%; }
    .ad-balloons .balloon:nth-child(3) { top: 30%; left: 55%; width: 15%; height: 15%; }
    .ad-balloons .balloon:nth-child(4) { top: 43%; left: 75%; width: 15%; height: 15%; }
    .ad-balloons .balloon:nth-child(5) { top: 70%; left: 15%; width: 15%; height: 15%; }
}
@media (min-width: 1600px) {
    .ad-balloons .balloon h2 {
        font-size: 30px;
    }
}
.ad-balloons .balloon.left {
    float: left;
}


.timer {
    position: fixed;
    left: 10px;
    right: 10px;
    bottom: 10px;
    height: 30px;
    z-index: 1000;
}
.time {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff265;
}


.sujet-sub #sujet {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.sujet-sub #sujet .balloon {
    opacity: 1;
}
.sujet-sub .ad-balloons .balloon:nth-child(1) { top: 15%; left: 50%; width: 25%; height: 15%; }
.sujet-sub .ad-balloons .balloon:nth-child(2) { top: 35%; left: 70%; width: 25%; height: 15%; }
.sujet-sub .ad-balloons .balloon:nth-child(3) { top: 55%; left: 55%; width: 25%; height: 15%; }
.sujet-sub .ad-balloons .balloon:nth-child(4) { top: 60%; left: 75%; width: 25%; height: 15%; }
.sujet-sub .ad-balloons .balloon:nth-child(5) { top: 53%; left: 15%; width: 25%; height: 15%; }