.page-hl-cont {
    position: fixed;
    top: 10px;
    height: 55px;
    left: 15px;
    right: 80px;
    overflow: hidden;
    pointer-events: none;
    z-index: 200;
}

/* ANIMATION */
.page-hl-cont .page-hl {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateX(-180deg);
    backface-visibility: hidden;
    transition: all 500ms cubic-bezier(0.000, 1, 0.000, 0.75);
}
.page-hl-cont .page-hl.active {
    transform: rotateX(0deg);
}
.page-hl-cont .page-hl.active ~ .page-hl {
    transform: rotateX(180deg);
}


.page-hl-cont h1 {
    font-size: 20px;
}
@media (min-width: 768px) {
    .page-hl-cont {
        height: 110px;
        left: 20px;
    }
    .page-hl-cont h1 {
        font-size: 35px;
    }
}
@media (min-width: 992px) {
    .page-hl-cont h1 {
        font-size: 50px;
    }
}
@media (min-width: 1200px) and (min-height: 750px) {
    .page-hl-cont {
        height: 150px;
    }
    .page-hl-cont h1 {
        font-size: 70px;
    }
}