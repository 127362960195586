.balloon {
    background: #fff476;
    position: relative;
    max-width: 400px;
    padding: 20px;
    margin: 50px auto;
}
.balloon:before {
    content: '';
    position: absolute;
    top: -50px;
    left: 30%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 51px 0 0 25px;
    border-color: transparent transparent transparent #fff476;
}
.balloon.left:before {
    bottom: 12px;
    top: auto;
    transform: rotate(90deg) scaleX(-1) scale(3);
    left: auto;
    right: -85px;
}
.balloon.right:before {
    bottom: 12px;
    top: auto;
    transform: rotate(-90deg) scale(3);
    left: -85px;
}
.balloon.purple {
    background: #9093c8;
}
.balloon.purple:before {
    border-color: transparent transparent transparent #9093c8;
}
.balloon.green {
    background: #c6de89;
}
.balloon.green:before {
    border-color: transparent transparent transparent #c6de89;
}
.balloon.red {
    background: #fcc394;
}
.balloon.red:before {
    border-color: transparent transparent transparent #fcc394;
}
.balloon.blue {
    background: #9fdbed;
}
.balloon.blue:before {
    border-color: transparent transparent transparent #9fdbed;
}