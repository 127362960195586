.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}
.slider .slide {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 150px 0 0;
    background: #fff;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transform: translateX(-100%);
    transition: transform 600ms cubic-bezier(0.000, 0.740, 0.000, 1.000);
}
.slider .slide > .container {
    padding-bottom: 150px;
}
@media (min-width: 768px) {
    .slider .slide {
        padding: 110px 0 0;
    }
}
@media (min-width: 1200px) and (min-height: 750px) {
    .slider .slide {
        padding: 200px 0 200px;
    }
}
.slider .slide.active {
    transform: translateX(0);
}
.slider .slide.active ~ .slide {
    transform: translateX(100%);
}
.slide-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 150ms ease-out;
    overflow: hidden;
}
.slide-bg .slide-bg-img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100%;
    min-height: 100%;
}
.license-group {
    position: absolute;
    top: 110px;
    left: 0;
    right: 0;
    bottom: 80px;
    overflow-y: auto;
    opacity: 0;
    z-index: 1;
}
.license-group.active {
    opacity: 1;
    z-index: 100;
}
@media (min-width: 1200px) {
    .license-group {
        top: 150px;
    }
}

/* SLIDER NAVIGATION */
.slider-nav {
    position: fixed;
    top: 45px;
    left: 0px;
    padding: 10px 25px 10px 15px;
    font-size: 30px;
    color: #fff265;
    display: none;
    z-index: 100;
    text-align: center;
}
.slider-nav.active {
    display: block;
}
@media (max-width: 767px) {
    .slider-nav {
        height: 40px;
        width: 40px;
        line-height: 50px;
        background: #fff;
    }
}
@media (min-width: 768px) {
    .slider-nav {
        font-size: 60px;
        top: 150px;
        padding-right: 15px;
    }
}
@media (min-width: 1200px) and (min-height: 750px) {
    .slider-nav {
        font-size: 80px;
        top: 170px;
        left: 50px;
        padding: 0;
    }
}


/* COURSE DETAILS */
.course-details {
    display: none;
}
.course-details.active {
    display: block;
}