.modal {
    background: rgba(0, 0, 0, 0.5);
}
.modal-content {
    border-radius: 0;
    border: none;
    box-shadow: none !important;
}
.modal-footer {
    text-align: left;
    border-top: none;
}
.modal-footer h1 {
    margin-bottom: 15px;
}
.modal-next,
.modal-prev,
.modal-close {
    cursor: pointer;
    font-size: 25px;
    color: #fff265;
}
.modal-next,
.modal-prev {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.modal-prev {
    left: -100px;
}
.modal-next {
    right: -100px;
}
.modal-close {
    /*background: #fff;*/
    padding: 10px;
}
@media (min-width: 768px) {
    .modal-next,
    .modal-prev,
    .modal-close {
        font-size: 35px;
    }
}
@media (min-width: 1200px) and (min-height: 750px) {
    .modal-dialog {
        max-width: 90vw;
        width: 1000px;
    }
    .modal-close {
        position: absolute;
        /*left: -100px;*/
        font-size: 50px;
    }
    .modal-next,
    .modal-prev {
        font-size: 80px;
    }
    .modal-close {
        background: none;
        padding: 10px;
    }
}

#modal-document,
.license-detail-document {
    display: none;
}
#modal-document.active,
.license-detail-document.active {
    display: block;
}


/* TODO TEMP */
#modal-document iframe {
    width: 100%;
    height: 70vh;
    background: #fff;
}