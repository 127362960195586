.course-details-contd {
    margin-bottom: 50px;
}
.course-details-cont h3 {
    margin: 2em 0 0.2em;
}
@media (min-width: 768px) {
    .course-details-cont {
        margin-bottom: 100px;
    }
}
.course-details-block {
    padding: 7.5px;
    font-size: 1.2em;
}
.course-details-block:nth-child(odd) {
    background: #9fdbed;
}
.course-details-block:nth-child(even) {
    background: rgba(159, 219, 237, 0.6);
}
.course-details-block:hover {
    outline: 2px solid #fff;
}
