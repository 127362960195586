.enrol-btn {
    position: fixed;
    right: 110px;
    bottom: 12px;
    padding: 8px 20px 8px 20px;
    transform-origin: 0 100%;
    background: #9fdbed;
    transition: transform 150ms ease-out;
    z-index: 10;
}
@media (min-width: 768px) {
    .enrol-btn {
        right: 0;
        bottom: 100px;
        padding: 15px 100px 30px 30px;
        transform: translateX(100%) rotate(-90deg);
    }
}
.enrol-btn h3 {
    margin: 0;
}
