@import url(/static/css/style/icons.css);
@import url(/static/css/style/lib/bootstrap.min.css);
/*@import "/static/css/style/lib/jquery.fullpage.min.css";*/
@import url(/static/css/style/lib/suit-css-grid.css);
@import url(/static/css/style/lib/magnific-popup.css);
/*@import "/static/css/style/lib/picker-classic.css";*/
/*@import "/static/css/style/lib/picker-classic.date.css";*/
@import url(/static/css/style/media-queries.css);
@import url(/static/css/style/btn.css);
@import url(/static/css/style/menu.css);
@import url(/static/css/style/page-frame.css);
@import url(/static/css/style/brand-name.css);
@import url(/static/css/style/page-hl.css);
@import url(/static/css/style/slider.css);
@import url(/static/css/style/page-top-licenses.css);
@import url(/static/css/style/page-top-locations.css);
@import url(/static/css/style/map.css);
@import url(/static/css/style/location-details.css);
@import url(/static/css/style/person-card.css);
@import url(/static/css/style/enrol-btn.css);
@import url(/static/css/style/modal.css);
@import url(/static/css/style/license-details.css);
@import url(/static/css/style/enrol-form.css);
@import url(/static/css/style/enrol-confirm.css);
@import url(/static/css/style/course-details.css);
@import url(/static/css/style/balloon.css);
@import url(/static/css/style/sujet.css);
@import url(/static/css/style/cookie.css);
@import url(/static/css/style/social.css);
@import url(/static/css/style/service-page.css);
.no-margin {
  margin: 0; }

.no-margin-bottom {
  margin-bottom: 0; }

hr {
  border-top: 10px solid #fff265; }

.inline-block {
  display: inline-block; }

.light-gray {
  color: #999; }

.img-text h1,
.img-text h2,
.text-img h1,
.text-img h2,
.text-text-text h2,
.text-ul {
  display: inline-block;
  position: relative;
  z-index: 1; }
  .img-text h1:before,
  .img-text h2:before,
  .text-img h1:before,
  .text-img h2:before,
  .text-text-text h2:before,
  .text-ul:before {
    content: '';
    position: absolute;
    left: -0.2em;
    right: -0.2em;
    bottom: -0.1em;
    height: 0.5em;
    background: #fff265;
    z-index: -1; }

.no-text-ul:before {
  content: none; }

.text-right {
  text-align: right; }

.parallaxify-cont {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden; }

.parallaxify-cont.relative {
  position: relative;
  right: auto;
  bottom: auto;
  width: 100vw;
  height: 80vh; }
  @media (min-width: 450px) {
    .parallaxify-cont.relative {
      height: 60vh; } }
  @media (min-width: 550px) {
    .parallaxify-cont.relative {
      height: 70vh; } }
  @media (min-width: 768px) {
    .parallaxify-cont.relative {
      height: 80vh; } }
  @media (min-width: 1024px) {
    .parallaxify-cont.relative {
      height: 90vh; } }

#page-cont > section:first-child,
#page-cont > section:last-child,
#page-cont > div:first-child,
#page-cont > div:last-child {
  margin-bottom: 80px; }

#page-cont > section:first-child:last-child,
#page-cont > div:first-child:last-child {
  margin-bottom: 0; }

.img-full-width {
  width: 100%; }

@media (min-width: 768px) {
  .pull-right-sm {
    float: right; }
  .pull-left-sm {
    float: left; } }

.row.narrow {
  margin-right: -5px;
  margin-left: -5px; }

.row.narrow > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px; }

.position-relative {
  position: relative; }

.checkbox {
  display: inline-block;
  height: 28px;
  position: relative;
  margin: 5px auto;
  background: #fff;
  border-radius: 50px; }

.checkbox .label-style {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  left: 4px;
  cursor: pointer;
  background: #fff;
  border-radius: 50px;
  border: 2px solid #9fdbed; }

.checkbox .label-style:after {
  content: '';
  width: 9px;
  height: 5px;
  position: absolute;
  top: 5px;
  left: 4px;
  border: 3px solid #000;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  transform: rotate(-45deg); }

.checkbox .label-style:hover:after {
  opacity: 0.3; }

input[type=checkbox] {
  visibility: hidden; }

input[type=checkbox]:checked + .label-style:after {
  opacity: 1; }

.checkbox h3,
.radio h3 {
  display: inline-block;
  padding-left: 11px;
  margin: 5px 10px 0 0;
  cursor: pointer; }

.radio {
  display: inline-block;
  height: 28px;
  position: relative;
  margin: 20px auto;
  border-radius: 50px; }

.radio .label-style {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 4px;
  border-radius: 50px;
  border: 2px solid #9fdbed; }

.radio .label-style:after {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #000;
  opacity: 0;
  border-radius: 50px; }

.radio .label-style:hover:after {
  opacity: 0.3; }

input[type=radio] {
  visibility: hidden; }

input[type=radio]:checked + .label-style:after {
  opacity: 1; }

label.required:after,
span.required-desc.required:after {
  content: '*';
  color: #9fdbed;
  font-size: 1.3em; }

input:focus {
  outline: 2px solid #fff265; }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #9fdbed; }

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: none; }

.Grid-no-bootstrap .Grid-cell {
  padding: 5px; }

@media (min-width: 768px) {
  .Grid-no-bootstrap .Grid-cell {
    max-width: 50%; } }

@media (min-width: 1200px) {
  .Grid-no-bootstrap .Grid-cell {
    max-width: 33.333333333%; } }

.alert {
  display: inline-block;
  padding: 0 0.3em;
  color: #fff;
  background: #c5de89; }

.alert-box {
  background: #c5de89;
  margin: 0 10px;
  padding: 5px; }

.alert-box.big {
  margin: 25px 0;
  padding: 20px; }

.fuhrpark-img {
  margin-bottom: 30px; }

.bottom-spacer {
  height: 150px; }

::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

::-webkit-scrollbar-button {
  background-color: black; }

::-webkit-scrollbar-track {
  background-color: #333333; }

::-webkit-scrollbar-track-piece {
  background-color: black; }

::-webkit-scrollbar-thumb {
  height: 10px;
  background-color: #fff265;
  border-radius: 0px; }

::-webkit-scrollbar-corner {
  background-color: #eeeeee; }

.mfp-arrow:after, .mfp-arrow:before {
  content: none;
  display: none; }

.mfp-arrow .icon {
  color: white;
  pointer-events: none; }

.mfp-arrow.mfp-arrow-left .icon {
  transform: rotate(180deg); }

@font-face {
  font-family: 'LabGrotesque-Bold';
  src: url("/static/fonts/LabGrotesque-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'LabGrotesque-Bold';
  src: url("/static/fonts/LabGrotesque-Bold.eot");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'LabGrotesque-Bold';
  src: url("/static/fonts/LabGrotesque-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

a,
a:hover,
a:focus,
a:active {
  color: inherit; }

.a-ul a {
  text-decoration: underline; }
  .a-ul a:focus, .a-ul a:active, .a-ul a:hover {
    text-decoration: none; }

body {
  font-family: LabGrotesque-Bold, sans-serif;
  color: #000; }

h1, h2, h3, h4, h5 {
  font-family: LabGrotesque-Bold, sans-serif;
  line-height: 1; }

p {
  margin: 0 0 1em 0; }

.underline {
  text-decoration: underline; }

.text-center {
  text-align: center; }

h1 {
  line-height: 1;
  font-size: 20px; }

h2 {
  font-size: 20px; }

h3 {
  font-size: 12px; }

@media (min-width: 768px) {
  h1 {
    font-size: 30px; }
  h2 {
    font-size: 25px; }
  h3 {
    font-size: 18px; } }

@media (min-width: 1200px) and (min-height: 750px) {
  h1 {
    font-size: 40px; }
  h2 {
    font-size: 35px; }
  h3 {
    font-size: 20px; } }

/* PREVENT MOMENTUM SCROLLING */
html,
body {
  height: 100%;
  width: 100%;
  overflow: auto; }

/* LOGO */
#logo-cont {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 60px;
  z-index: 10000; }

@media (min-width: 768px) {
  #logo-cont {
    width: 90px;
    top: 30px;
    right: 30px; } }

@media (min-width: 1200px) and (min-height: 750px) {
  #logo-cont {
    width: 200px; } }

.container {
  max-width: calc(100vw - 50px); }

.narrow-container .container {
  max-width: 500px; }

@media (min-width: 768px) {
  .container,
  .narrow-container .container {
    max-width: calc(100vw - 190px); } }

@media (min-width: 1390px) {
  .container,
  .narrow-container .container {
    max-width: 1200px; } }

#group-pic-cont {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden; }

#group-pic-cont::-webkit-scrollbar {
  display: none; }

#group-pic,
#silhouettes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 320%;
  overflow: hidden;
  height: auto; }

#silhouettes {
  width: 100%;
  max-height: 10000px; }

@media (min-width: 450px) {
  #group-pic {
    width: 200%; } }

@media (min-width: 768px) {
  #group-pic,
  #silhouettes {
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

#group-pic #group-pic-bg,
#group-pic #group-pic-bg-mask {
  width: 100%;
  pointer-events: none; }

#group-pic-bg-mask {
  position: absolute;
  width: 100%;
  z-index: 10;
  height: auto;
  left: 0;
  top: 0; }

#group-pic .portrait {
  position: absolute;
  top: 50%;
  left: 50%;
  background: #333;
  width: 4.8%;
  border-radius: 3px;
  pointer-events: none; }

#group-pic .portrait .portrait-img {
  position: relative;
  padding-bottom: 131%;
  overflow: hidden;
  border-radius: 3px; }

#group-pic .portrait img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; }

#group-pic .portrait:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 2px 2px 14px 0px rgba(0, 0, 0, 0.5);
  z-index: 3;
  border-radius: 3px; }

#group-pic #portrait-1 {
  top: 23.9%;
  left: 19.2%;
  width: 6%; }

#group-pic #portrait-2 {
  left: 25.9%;
  top: 52.1%;
  width: 6.5%; }

#group-pic #portrait-3 {
  top: 50.3%;
  left: 42.1%;
  width: 6.2%; }

#group-pic #portrait-4 {
  top: 20.5%;
  left: 51.9%;
  width: 5.7%;
  transform: skew(-3deg, 1deg); }

#group-pic #portrait-5 {
  top: 48.7%;
  left: 60.1%;
  width: 6%;
  transform: skew(1deg, -2deg); }

#group-pic #portrait-6 {
  top: 32.1%;
  left: 80.3%;
  width: 6.9%;
  transform: skew(0deg, -2deg); }

#silhouettes {
  z-index: 20; }

#silhouettes polygon {
  opacity: 0;
  cursor: pointer; }

#silhouettes polygon:hover {
  opacity: 1; }

#group-pic .portrait .sign {
  color: #fff;
  text-align: center;
  background: #9fdbed;
  font-size: 60px;
  line-height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  z-index: 4;
  transition: transform 250ms cubic-bezier(0, 1, 0, 0.75); }

#group-pic .portrait.active .sign {
  transform: translateY(0); }

/* ANIMATION */
#group-pic .portrait img {
  transform: translate(50%, -50%) translateZ(0); }

#group-pic .portrait img.active,
#group-pic .portrait img.old-active {
  transition: transform 500ms ease-out; }

#group-pic .portrait img.active {
  transform: translate(-50%, -50%) translateZ(0);
  z-index: 2; }

#group-pic .portrait img.old-active {
  transform: translate(-150%, -50%) translateZ(0);
  z-index: 1; }

.slide.active #group-pic .portrait img.active {
  transition-duration: 800ms;
  transition-timing-function: cubic-bezier(0.51, 1.68, 0.7, 0.85); }

/* SELECT COURSE BALLOON */
.balloon.balloon-select-course {
  position: absolute;
  top: 20%;
  left: 5%;
  max-width: 10%;
  z-index: 100;
  padding: 5px;
  text-align: center; }

.balloon.balloon-select-course:before {
  transform: rotate(90deg);
  top: auto;
  bottom: 0;
  left: auto;
  right: -30px;
  z-index: -1; }

.balloon.balloon-select-course h1,
.balloon.balloon-select-course h2,
.balloon.balloon-select-course h3,
.balloon.balloon-select-course h4 {
  display: inline-block;
  max-width: 100%;
  font-size: 15px;
  margin: 5px 0; }

@media (min-width: 768px) {
  .balloon.balloon-select-course {
    top: 5%;
    left: 28%;
    padding: 20px;
    max-width: 20%; }
  .balloon.balloon-select-course:before {
    right: auto;
    left: -30px;
    transform: rotate(-90deg); }
  .balloon.balloon-select-course h1,
  .balloon.balloon-select-course h2,
  .balloon.balloon-select-course h3,
  .balloon.balloon-select-course h4 {
    font-size: 20px; } }

@media (min-width: 992px) {
  .balloon.balloon-select-course {
    top: 30%; } }

@media (min-width: 992px) and (min-height: 750px) {
  .balloon.balloon-select-course {
    top: 14%; } }

#scroll-down {
  display: none;
  position: fixed;
  bottom: 20px;
  height: 60px;
  width: 60px;
  margin-left: -15px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff265;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  z-index: 11; }
  @media (min-width: 1024px) {
    #scroll-down {
      display: flex; } }
  #scroll-down .icon-mouse {
    font-size: 2em; }

.license-card {
  position: relative;
  color: #fff;
  background: #fff9b8;
  cursor: pointer;
  padding: 80px 30px 30px;
  z-index: 1;
  margin-bottom: 10px;
  width: 100%; }

.license-card:hover {
  background: #9fdbed; }

.license-card h1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  text-align: center;
  font-size: 80px;
  line-height: 0.75;
  z-index: -1; }

.license-card img {
  height: 60px;
  margin-top: 50px; }

.license-card p {
  margin: 0; }

@media (min-width: 768px) {
  .license-card h1 {
    font-size: 120px; } }

@media (min-width: 1200px) {
  .license-card h1 {
    font-size: 150px; } }

@media (min-width: 992px) {
  .license-card img {
    height: 120px; } }

/* DETAILS */
.license-group .row.narrow {
  display: flex; }

.license-card-details {
  position: relative;
  padding-bottom: 60px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%; }

.license-card-details.no-slide {
  cursor: auto; }

.license-card-details.no-slide:hover .lc-cont {
  background: #fff9b8; }

.license-group-selector {
  display: none;
  position: absolute;
  top: 60%;
  right: 150px;
  z-index: 1000;
  transform: translateY(-50%); }

.license-group-selector ul {
  list-style: none;
  color: #fff9b8; }

.license-group-selector li {
  position: relative;
  display: block;
  padding: 0;
  margin-bottom: 20px; }

.license-group-selector li:before {
  transform: translateY(10px);
  opacity: 0;
  background: #fff9b8;
  height: 0.8em;
  transition: all 250ms ease-out; }

.license-group-selector li:hover,
.license-group-selector li.active {
  color: #fff265; }

.license-group-selector li:hover:before,
.license-group-selector li.active:before {
  opacity: 1;
  transform: translateY(0); }

.license-group-selector a,
.license-group-selector a:hover,
.license-group-selector a:focus,
.license-group-selector a:active {
  text-decoration: none; }

.license-group-selector li h1 {
  margin: 0; }

.lc-icon {
  text-align: center; }

.lc-icon img {
  margin: 0 auto;
  height: 60px; }

.lc-cont {
  background: #fff9b8;
  padding: 10px 30px 10px 10px;
  flex: 3 3 auto; }

.license-card-details:hover .lc-cont {
  background: #9fdbed; }

.lc-cont h2 {
  font-size: 14px; }

.lc-cont h3 {
  margin-bottom: 0; }

.lc-cont ul {
  margin-top: 0; }

.lc-cont p {
  margin: 5px 0 0;
  line-height: 1.5; }

.lc-info {
  text-align: right;
  margin-top: -20px;
  margin-right: -20px;
  padding-bottom: 10px; }

.lc-info img {
  width: 40px;
  height: auto; }

@media (min-width: 1200px) {
  .lc-icon img {
    height: 100px; }
  .lc-cont h2 {
    font-size: 25px; } }

@media (min-width: 1600px) and (min-height: 750px) {
  .license-group-selector {
    display: block; } }

.enrol-btns {
  margin-top: 50px; }

.enrol-btns .btn {
  margin-left: 15px; }

.enrol-btns,
.license-details-btns {
  display: none; }

.enrol-btns.active,
.license-details-btns.active {
  display: block; }

.img-text {
  padding: 0 10px; }
  @media (min-width: 1200px) {
    .img-text {
      padding: 0 80px; } }
  .img-text .row {
    display: flex;
    flex-wrap: wrap; }
    .img-text .row [class^=col] {
      flex: 0 0 100%;
      display: flex;
      align-items: center;
      background-repeat: no-repeat !important;
      background-size: cover !important; }
      @media (min-width: 768px) {
        .img-text .row [class^=col] {
          flex: 0 0 calc(50% - 1px); } }
  .img-text .img-cont {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -10px;
    width: calc(100% + 10px);
    padding-bottom: 60vw;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important; }
    .img-text .img-cont img {
      margin: 50px auto 0; }
    @media (min-width: 768px) {
      .img-text .img-cont {
        padding-bottom: 0;
        height: 100%;
        width: calc(100% + 5px); }
        .img-text .img-cont img {
          margin: 0; } }
  .img-text .text-cont {
    width: 500px;
    max-width: 90%;
    margin: 0 auto;
    padding: 20px; }
    @media (min-width: 768px) {
      .img-text .text-cont {
        margin: 0 auto 0 0;
        padding: 40px;
        width: auto;
        max-width: none;
        min-height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: center; } }
    @media (min-width: 1200px) {
      .img-text .text-cont {
        padding: 80px; } }
    .img-text .text-cont h1:before {
      background: #fff; }
    .img-text .text-cont h1:after {
      content: ' ';
      width: 100%;
      display: block; }
    .img-text .text-cont h1 + div {
      margin-top: -1.6em; }
      @media (min-width: 768px) {
        .img-text .text-cont h1 + div {
          margin-top: -2.3em; } }
      @media (min-width: 1200px) and (min-height: 750px) {
        .img-text .text-cont h1 + div {
          margin-top: -3em; } }
  @media (min-width: 768px) {
    .img-text.img-right [class^=col]:first-child {
      order: 2; }
    .img-text.img-right .img-cont {
      margin-left: -5px;
      margin-right: -12px;
      width: calc(100% + 20px); } }

.text-text-text .text-cont {
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 768px) {
    .text-text-text .text-cont {
      padding-left: 0;
      padding-right: 0; } }

.kontakt {
  display: flex;
  margin-top: 80px;
  background-repeat: no-repeat !important;
  background-size: 50% !important;
  background-position: top right !important;
  background-color: #fff265 !important;
  padding: 0 20px; }
  @media (min-width: 768px) {
    .kontakt {
      padding: 0;
      background-size: 25% !important; } }
  .kontakt h2.hl {
    margin: -0.6em 0 1em; }
  .kontakt .location-cont {
    display: flex;
    flex-wrap: wrap; }
    .kontakt .location-cont .location {
      flex: 0 0 100%; }
      @media (min-width: 768px) {
        .kontakt .location-cont .location {
          flex: 0 0 50%; } }
