.map-parallax {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
}
.map-cont {
    position: absolute;
    top: 100px;
    right: -80px;
    width: 160vw;
}
.map {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 0;
}
.map {
    width: 100%;
    height: 100%;
}
.map-sizer {
    position: relative;
    padding-bottom: 108.18737224%;
}
.map #markers g {
    cursor: pointer;
}
.map #markers g:hover path,
.map #markers g.active path {
    fill: #9fdbed;
}

/* MAP MARKERS */
.map-marker {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: left;
    cursor: pointer;
}
.map-marker h3 {
    margin-bottom: 0;
}
.logo-habual {
    width: 80px;
}
#map-sd { top: 24%; left: 67%; }
#map-an { top: 35%; left: 73%; }
#map-ri { top: 53.5%; left: 58%; }
#map-al { top: 49%; left: 30%; }
#map-fb { top: 69%; left: 60%; }

/* INFO BOXES */
.info-box {
    display: none;
    position: absolute;
    padding: 30px;
    background: #9fdbed;
    top: 150px;
    left: 15%;
    width: 500px;
    transform: rotateX(-180deg);
    backface-visibility: hidden;
    transition: transform 800ms cubic-bezier(0.000, 0.740, 0.000, 1.000);
}
.info-box address {
    font-style: normal;
}
.info-box h2 {
    line-height: 1.3;
    margin: 0 0 0.5em;
}
.info-box h3 {
    line-height: 1.3;
    margin: 0 0 10px;
}
@media (min-width: 768px) {
    .map-cont {
        top: 80px;
        left: 20%;
        width: 60%;
    }
}
@media (min-width: 1200px) and (min-height: 750px) {
    .map-cont {
        top: 120px;
        left: auto;
        right: 200px;
        width: 40vw;
    }
    .info-box {
        display: block;
    }
    .info-box.active {
        transform: rotateX(0deg);
    }
}
