.enrol-confirm-cont {
    background-color: #fff265;
    padding: 20px 20px 10px 20px;
    margin: 50px 0;
}

.enrol-confirm-cont li {
    list-style-type: none;
}

.enrol-confirm-cont li div {
    display: inline-block;
}

.enrol-confirm-data {
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding-bottom: 10px;
}

.enrol-confirm-data-cont {
    display: inline-block;
    position: relative;
    z-index: 1;
    line-height: 1;
}

.enrol-confirm-data-cont:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: .4em;
    background: #fff;
    z-index: -1;
}