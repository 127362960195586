.page-top-locations {
    position: fixed;
    top: 45px;
    height: 50px;
    left: 0;
    right: 0;
    overflow: hidden;
    background: #fff;
    pointer-events: none;
    z-index: 20;
}
.page-top-locations .location {
    position: absolute;
    width: 100%;
    top: 17px;
    left: 150px;
    transform: translateX(100%);
    transition: transform 150ms ease-in;
}
.page-top-locations .location.active {
    transform: translateX(0);
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.000, 0.740, 0.000, 1.000);
}
.page-top-locations h2 {
    font-size: 14px;
    margin: 0;
}
@media (min-width: 768px) {
    .page-top-locations {
        left: 65%;
        top: 40px;
        height: 80px;
        background: none;
    }
    .page-top-locations .location {
        left: 10px;
        top: 0;
    }
    .page-top-locations h2 {
        font-size: 20px;
    }
}
@media (min-width: 1500px) and (min-height: 750px) {
    .page-top-locations {
        left: 70%;
        top: 50px;
    }
    .page-top-locations h2 {
        font-size: 35px;
    }
}
