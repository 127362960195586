.page-top-licenses {
    position: fixed;
    top: 60px;
    left: 60px;
    height: 55px;
    right: 0;
    overflow: hidden;
    pointer-events: none;
    z-index: 200;
}
.page-top-licenses img {
    background: #fff;
    height: 30px;
}
.page-top-licenses .license-letter {
    font-size: 40px;
    margin: 0;
    color: #fff265;
}
@media (min-width: 768px) {
    .page-top-licenses {
        top: 30px;
        height: 100px;
        left: 55%;
    }
    .page-top-licenses img {
        background: none;
        height: 40px;
        top: 40px;
        left: 50%;
    }
    .page-top-licenses .license-letter {
        font-size: 80px;
    }
}
@media (min-width: 992px) {
    .page-top-licenses img {
        height: 60px;
    }
}
@media (min-width: 1500px) and (min-height: 750px) {
    .page-top-licenses img {
        height: 80px;
    }
    .page-top-licenses .license-letter {
        font-size: 100px;
    }
}
.page-top-licenses .license-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transform: translateX(100%);
    transition: transform 300ms ease-in;
}
.page-top-licenses .license-icon.active {
    transform: translateX(0);
    transition-duration: 500ms;
    transition-timing-function: cubic-bezier(0.000, 0.740, 0.000, 1.000);
}