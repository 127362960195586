#menu {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 10000;
}
#menu h2 {
    font-size: 20px;
    margin: 0;
}
#menu-list-cont {
    margin-top: -11px;
    transition: all 300ms ease;
    overflow: hidden;
    pointer-events: none;
}
#menu.active #menu-list-cont {
    pointer-events: all;
}
#menu-list {
    list-style: none;
    padding: 15px;
    background: #fff265;
    margin: -25px 0 0 0;
}
#menu-list li {
    margin-bottom: 10px;
    line-height: 1.2;
}
#menu-list li:last-child {
    margin-bottom: 0;
}
#menu-list .btn {
    font-size: 15px;
}
#menu-list .btn:before {
    background: #fff;
    opacity: 0;
    transform: translateY(10px) translateZ(0);
    transition: all 250ms ease-out;
}
#menu-list .btn:hover:before,
#menu-list .active .btn:before {
    background: #fff;
    opacity: 1;
    transform: translateY(0) translateZ(0);
}
#menu-bg-logo {
    display: none;
}
#menu-close {
    position: absolute;
    left: 20px;
    top: 30px;
    color: #000;
    font-size: 30px;
    display: none;
    z-index: 1000;
}
a.link-new-tab .icon-new-tab {
    visibility: hidden;
    font-size: 0.7em;
}
a.link-new-tab:hover .icon-new-tab {
    visibility: visible;
}
@media (min-width: 768px) {
    #menu {
        right: auto;
        top: 50%;
        left: 30px;
        min-height: 380px;
        max-width: 0;
        transition: max-width 200ms ease-out;
    }
    #menu h2 {
        font-size: 30px;
    }
    #menu-list {
        padding: 20px;
        padding-top: 40px;
    }
    #menu-list .btn {
        font-size: 20px;
    }
}
@media (max-width: 1199px), (max-height: 749px) {
    #menu-list-cont {
        position: fixed;
        visibility: hidden;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        z-index: 1000000;
        background: #fff265;
        transform: translateY(-20px);
        opacity: 0;
        transition: all 150ms ease-out;
    }
    #menu.active #menu-list-cont {
        visibility: visible;
        transform: translateY(0);
        opacity: 1;
    }
    #menu-bg-logo {
        display: block;
        position: absolute;
        bottom: 20%;
        width: 220%;
        left: 20%;
        height: auto;
        pointer-events: none;
    }
    #menu-list {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        background: none;
        transform: translateY(-50%);
    }
    #menu-list li {
        transform: translateY(-20px);
        opacity: 0;
        transition: all 150ms ease-out;
    }
    #menu-list .btn {
        font-size: 30px;
    }
    #menu-close {
        display: block;
    }
    a.link-new-tab .icon-new-tab {
        visibility: visible;
    }
    #menu.active #menu-list li {
        opacity: 1;
        transform: translateY(0);
    }
    #menu-list li:nth-child(1) { transition-delay: 30ms; }
    #menu-list li:nth-child(2) { transition-delay: 60ms; }
    #menu-list li:nth-child(3) { transition-delay: 90ms; }
    #menu-list li:nth-child(4) { transition-delay: 120ms; }
    #menu-list li:nth-child(5) { transition-delay: 150ms; }
    #menu-list li:nth-child(6) { transition-delay: 180ms; }
    #menu-list li:nth-child(7) { transition-delay: 210ms; }
    #menu-list li:nth-child(8) { transition-delay: 240ms; }
    #menu-list li:nth-child(9) { transition-delay: 270ms; }
    #menu-list li:nth-child(10) { transition-delay: 300ms; }
}
@media (min-width: 1200px) and (min-height: 750px) {
    #menu {
        top: auto;
        bottom: 130px;
    }
    #menu.active {
        max-width: 500px;
    }
    #menu h2 {
        font-size: 40px;
    }
    #menu-list {
        padding: 50px 20px 20px;
    }
    #menu-list .btn {
        font-size: 25px;
    }
    #menu #menu-list-cont {
        max-height: 0;
    }
    #menu.active #menu-list-cont {
        max-height: 60vh;
    }
}
