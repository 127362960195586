.location-details-cont {
    background: #fff;
    overflow-y: auto;
}
.location-details {
    display: none;
}
.location-details.active {
    display: block;
}
.location-detail-box {
    background: #fff265;
    padding: 20px;
    margin-bottom: 15px;
}
.location-detail-box .btn:before {
    background: #fff;
}
.location-detail-box h2,
.location-detail-box h3 {
    margin: 0 0 0.8em;
}
.location-detail-box h2 {
    line-height: 1;
    font-size: 15px;
}
@media (min-width: 768px) {
    .location-detail-box h2 {
        font-size: 20px;
    }
    .location-details-cont .container .row .row:first-child .person-card {
        margin-top: 150px;
    }
}
@media (min-width: 768px) {
    .location-detail-box h2 {
        font-size: 25px;
    }
}
